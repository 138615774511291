var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"homePageTop"},[_c('div',{staticClass:"homePageTopTitle"},[_vm._v("欢迎回来，"+_vm._s(_vm.userName || "--"))]),_c('div',{staticClass:"homePageTopSrarch"},[_c('van-search',{attrs:{"shape":"round","clearable":"","placeholder":"请搜索品牌名称/姓名/手机号"},on:{"search":_vm.onSearch,"clear":_vm.clearSearcg},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}}),_c('van-popover',{attrs:{"trigger":"click","placement":"bottom-end","actions":_vm.actions},on:{"select":_vm.onSelect},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('van-icon',{attrs:{"size":"24","color":"#333333","name":"filter-o"}})]},proxy:true}]),model:{value:(_vm.showPopover),callback:function ($$v) {_vm.showPopover=$$v},expression:"showPopover"}})],1)]),_c('div',{staticClass:"homePageBottom"},[_c('van-pull-refresh',{on:{"refresh":_vm.getUserSalesList},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[(_vm.getUserSalesList.length > 0)?_c('van-list',{attrs:{"finished":_vm.salesListFinished}},_vm._l((_vm.userSalesList),function(item){return _c('van-cell',{key:item.id},[_c('div',{staticClass:"gridVant"},[_c('div',{staticClass:"gridVantBrand gridVantBrandTime"},[_c('div',{staticClass:"brandName"},[_vm._v("品牌名称:")]),_c('div',{staticClass:"brandConent"},[_vm._v(_vm._s(item.brandName || '--'))])]),_c('div',{staticClass:"gridVantBrand"},[_c('div',{staticClass:"brandName"},[_vm._v("手机号:")]),_c('div',{staticClass:"brandConent"},[_vm._v(_vm._s(item.customerMobile || "--"))])]),_c('div',{staticClass:"gridVantBrand"},[_c('div',{staticClass:"brandName"},[_vm._v("状态:")]),_c('div',{staticClass:"brandConent",class:[
                  'brandConent',
                  item.status == '1'? 'status-pending': item.status == '4'? 'status-approved': item.status == '3'? 'status-rejected' : '',
                ]},[_vm._v(" "+_vm._s(item.status == "2" ? "后台待审核" : item.status == "1" ? "钱包待开通" : item.status == "3" ? "钱包开通失败" : item.status == "4" ? "钱包开通成功" : item.status == "0" ? "驳回" : "--")+" ")])])]),_c('div',{staticClass:"gridVant"},[_c('div',{staticClass:"gridVantBrand gridVantBrandTime"},[_c('div',{staticClass:"brandName"},[_vm._v("创建时间:")]),_c('div',{staticClass:"brandConent"},[_vm._v(_vm._s(_vm._f("moment")(item.createdTime)))])]),_c('div',{staticClass:"gridVantBrand"},[_c('div',{staticClass:"brandName"},[_vm._v("客户姓名:")]),_c('div',{staticClass:"brandConent"},[_vm._v(_vm._s(item.customerName || "--"))])]),(item.status == '0')?_c('div',{staticClass:"gridVantBrand gridVantBrandTime"},[_c('div',{staticClass:"brandName"},[_vm._v("备注:")]),_c('div',{staticClass:"brandConent"},[_vm._v(_vm._s(item.remark || '暂无'))])]):_vm._e(),_c('van-image',{staticClass:"gridVantBrandImage",attrs:{"width":"60","height":"44","fit":"cover","src":item.status == '4'
                    ? _vm.adoptSrc.image
                    : item.status == '2' || item.status == '3' || item.status == '1'
                    ? _vm.auditingSrc.image
                    : item.status == '0'
                    ? _vm.rejectSrc.image
                    : null}}),_c('div',{staticClass:"gridVantBrand"},[_c('div',{staticClass:"brandConent brandConentText",on:{"click":function($event){return _vm.goUserDetail(item.id)}}},[_vm._v(" 查看 ")]),_c('div',{staticClass:"brandConent brandConentText",class:item.status == '4'? 'btDisable' : '',on:{"click":function($event){return _vm.setUserDetail(item)}}},[_c('span',[_vm._v(" "+_vm._s(item.status == "1" || item.status == "3"? "开通钱包" :item.status == "2" || item.status == "0"? "编辑" : ''))])])])],1)])}),1):_c('van-empty',{attrs:{"description":"描述文字"}})],1)],1),_c('div',{staticClass:"Fbtom",on:{"click":_vm.gosetUserDetail}},[_c('van-icon',{staticStyle:{},attrs:{"color":"#0283EF","name":"add"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }