<template>
  <div>
    <!-- <button @click="$router.push('/myWalletBeans')">跳转到钱包</button> -->
    <div class="homePageTop">
      <!-- 测试跳转按钮 -->
      <!-- myWalletBeans -->
      <!-- inforMationcC -->
      <!-- myWalletBeansDetail -->
      <!-- resultInforCation -->
      <!-- bankCardDeatil -->
      <!-- 这边要注意页面的跳转问题。返回上一步是调不回去移动端的 -->
      <div class="homePageTopTitle">欢迎回来，{{ userName || "--" }}</div>
      <div class="homePageTopSrarch">
        <van-search
          v-model="searchValue"
          shape="round"
          clearable
          @search="onSearch"
          @clear="clearSearcg"
          placeholder="请搜索品牌名称/姓名/手机号"
        />
        <van-popover
          v-model="showPopover"
          trigger="click"
          placement="bottom-end"
          :actions="actions"
          @select="onSelect"
        >
          <template #reference>
            <van-icon size="24" color="#333333" name="filter-o" />
          </template>
        </van-popover>
      </div>
    </div>
    <!-- 下班部分 -->
    <div class="homePageBottom">
      <van-pull-refresh v-model="isLoading" @refresh="getUserSalesList">
        <van-list
          v-if="getUserSalesList.length > 0"
          :finished="salesListFinished"
        >
          <!-- @load="getUserSalesList" -->
          <van-cell v-for="item in userSalesList" :key="item.id">
            <div class="gridVant">
              <div class="gridVantBrand gridVantBrandTime">
                <div class="brandName">品牌名称:</div>
                <div class="brandConent">{{ item.brandName || '--' }}</div>
              </div>
           
              <div class="gridVantBrand">
                <div class="brandName">手机号:</div>
                <div class="brandConent">{{ item.customerMobile || "--" }}</div>
              </div>
              <div class="gridVantBrand">
                <div class="brandName">状态:</div>
                <div
                  class="brandConent"
                  :class="[
                    'brandConent',
                    item.status == '1'? 'status-pending': item.status == '4'? 'status-approved': item.status == '3'? 'status-rejected' : '',
                  ]"
                >
                  {{
                    item.status == "2"
                      ? "后台待审核"
                      : item.status == "1"
                      ? "钱包待开通"
                      : item.status == "3"
                      ? "钱包开通失败"
                      : item.status == "4"
                      ? "钱包开通成功"
                      : item.status == "0"
                      ? "驳回"
                      : "--"
                  }}
                </div>
              </div>
            </div>
            <div class="gridVant">
              <div class="gridVantBrand gridVantBrandTime">
                <div class="brandName">创建时间:</div>
                <div class="brandConent">{{ item.createdTime | moment  }}</div>
              </div>
              <div class="gridVantBrand">
                <div class="brandName">客户姓名:</div>
                <div class="brandConent">{{ item.customerName || "--" }}</div>
              </div>

             
              <div v-if="item.status == '0'" class="gridVantBrand gridVantBrandTime">
                <div class="brandName">备注:</div>
                <div class="brandConent">{{ item.remark || '暂无'}}</div>
              </div>
                <van-image
                class="gridVantBrandImage"
                  width="60"
                  height="44"
                  fit="cover"
                  :src="
                    item.status == '4'
                      ? adoptSrc.image
                      : item.status == '2' || item.status == '3' || item.status == '1'
                      ? auditingSrc.image
                      : item.status == '0'
                      ? rejectSrc.image
                      : null
                  "
                />
              <div class="gridVantBrand">
                <div
                  class="brandConent brandConentText"
                  @click="goUserDetail(item.id)"
                >
                  查看
                </div>
                <div
                :class="item.status == '4'? 'btDisable' : ''"
                  class="brandConent brandConentText"
                  @click="setUserDetail(item)"
                >
                  <span
                  > {{
                    item.status == "1" || item.status == "3"? "开通钱包" :item.status == "2" || item.status == "0"? "编辑" : ''
                  }}</span>
                </div>
              </div>
            </div>
          </van-cell>
        </van-list>
        <van-empty v-else description="描述文字" />
      </van-pull-refresh>
    </div>
    <div class="Fbtom" @click="gosetUserDetail">
      <van-icon color="#0283EF"  style="btBottom"  name="add" />
    </div>
  </div>
</template>

<script>
import { Notify } from "vant";
import {
  getUserDetail,
  getUserDetailName,
  getUserSalesList,
  getRegInviteLink
} from "@/api/user/index";

export default {
  name: "index",
  data() {
    return {
      userName: "",
      searchValue: "",
      userSalesList: [],
      showPopover: false,
      // 通过 actions 属性来定义菜单选项
      actions: [
        { text: "全部", value: "" },
        { text: "驳回", value: "0" },
        { text: "钱包待开通", value: "1" },
        { text: "后台待审核", value: "2" },
        { text: "钱包开通失败", value: "3" },
        { text: "钱包开通成功", value: "4" },
      ],
      salesListFinished: true,
      salesListRefreshing: false,
      isLoading: false,
      adoptSrc: { image: require("../../assets/auditingState/通过.png") },
      auditingSrc: { image: require("../../assets/auditingState/审核中.png") },
      rejectSrc: { image: require("../../assets/auditingState/驳回.png") },
    };
  },
  created() {
    this.getUserDetail();
    this.getUserSalesList();
    this.getUserDetailName();
  },
  mounted() {},
  methods: {
    /**
     * 代码描述:
     * 作者:liushiban
     * 创建时间:
     */
    getUserDetailName() {
      getUserDetailName()
        .then(() => {
        })
        .catch(() => {});
    },
    /**
     * 代码描述: 获取用户详情信息
     * 作者:liushiban
     * 创建时间:
     */
    getUserDetail() {
      getUserDetail()
        .then((res) => {
          this.userName = res.data.data.role;
        })
        .catch((error) => {
          return error
        });
    },
    // clearSearcg
    clearSearcg(){
      this.getUserSalesList()
    },
    /**
     * 代码描述:搜索框事件
     * 作者:liushiban
     * 创建时间:
     */
     onSearch(val) {
      console.log('343434')
      this.searchValue = val;
      this.getUserSalesList(val);
    },
    /**
     * 代码描述: 销售开户查询列表总
     * 作者:liushiban
     * 创建时间:
     */
    getUserSalesList(query, status) {
      this.isLoading = true;
      this.salesListFinished = false;
      getUserSalesList(query, status)
        .then((res) => {
          if (res.status == "200") {
            this.userSalesList = res.data.data;
            this.salesListFinished = true;
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.salesListFinished = true;
          console.log(error);
        });
    },
    /**
     * 代码描述:
     * 作者:liushiban
     * 创建时间:
     */
    onSelect(action) {
      let { value } = action;
      this.getUserSalesList(null, value);
    },
    /**
     * 代码描述: 列表刷新
     * 作者:liushiban
     * 创建时间:
     */
    onSalesListRefresh() {
      // 清空列表数据
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      // this.salesListLoading = true;
      this.getUserSalesList();
    },
    /**
     * 代码描述: 跳转查看详情页面
     * 作者:liushiban
     * 创建时间:
     */
    goUserDetail(id) {
      this.$router.push({
        name: "userSalesDetail",
        params: {
          userSalesId: id,
        },
      });
    },
    /**
     * 代码描述: 开户申请单事件
     * 作者:liushiban
     * 创建时间:
     */
    gosetUserDetail() {
      this.$router.push({
        name: "setUserDetail",
      });
    },
    /**
     * 代码描述: 编辑详情
     * 作者:liushiban
     * 创建时间:
     */
    setUserDetail(item) {
      let { id, status, enterpriseName} = item;
      // 1和3是可以去开通钱包的其他状态不能
      if (status == "1" || status == "3") {
        let bizUserId = `brand-${id}` || ''
        getRegInviteLink(bizUserId,enterpriseName)
          .then((responseData) => {
            if (responseData.data.code == '10000' && responseData.data.subCode == 'OK') {
              // 请求成功时的处理
              // 在这里可以对 responseData 进行进一步处理
              let { regInviteLink } = JSON.parse(responseData.data.data);
              // 跳转第三方链接
              window.location.href = regInviteLink || "";
            } else {
              Notify({ type: "warning", message: responseData.data.subMsg });
            }
          })
          .catch((error) => {
            // 请求失败时的处理
            return error;
          });
      } else {
        this.$router.push({
          name: "setUserDetail",
          params: {
            userSalesId: id,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.homePageTop {
  width: 100%;
  height: 100px;
  font-size: 18px;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  position: fixed;
  z-index: 40;
  background-color: #fff;
  .homePageTopTitle {
    text-align: left;
    font-weight: bold;
  }
  .homePageTopSrarch {
    color: #999999;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  .van-search {
    flex: 2;
    padding: 0 0;
    padding-right: 10px;
  }
}
.homePageBottom {
  height: 100vh;
  padding: 0 10px;
  padding-top: 102px;
  background-color: #f4f4f4;
  .van-cell {
    padding: 0 0;
    margin: 10px 0;
    display: flex;

    border-radius: 10px;
    box-sizing: border-box;
    .van-cell__value {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 10px;
      box-sizing: border-box;
    }
  }
  .gridVant {
    width: 50%;
    overflow: hidden;
    padding: 4px 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    .gridVantBrand {
      display: flex;
    }
    .gridVantBrandTime{
      position: relative;
    }
    .gridVantBrandImage{
      position: absolute;
      top: 0;
      right: 0;
    }
    .brandName {
      flex: 1;
      color: #b6b6b6;
      font-size: 12px;
      padding-right: 4px;
      text-align: right;
    }
    .brandConent {
      flex: 2;
      font-size: 14px;
      color: #222222;
      white-space: nowrap;
      text-overflow: ellipsis;

      overflow: hidden;
      font-weight: 500;
    }
    .brandConentText {
      width: 300px;
      font-size: 12px;
      color: rgb(47, 99, 255);
      font-weight: 300;
    }
  }
}
.Fbtom {
  position: fixed;
  width: 48px;
  height: 48px;
  right: 24px;
  bottom: 100px;
  z-index: 41;
}
.status-pending {
  color: rgb(208, 204, 18) !important;
}

.status-approved {
  color: green !important;
}

.status-rejected {
  color: red !important;
}
.btDisable {
  pointer-events: none !important;
  color: #e2e2e2 !important;
}
.Fbtom{
  .van-icon{
    font-size: 48px;
  }
}

</style>
